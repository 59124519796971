import { GET, GET_ALL, POST, PUT } from './Clients';

const PRODUCT_API_PREFIX = '/marketplace/product/v2';
const FRONTEND_APIS_PREFIX = '/marketplace/frontend-apis/v1';

export async function getScreenWishlist({ ctx, offset = 0, limit = 20, search, getTotal = false }) {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/screen/product/wishlist`,
    params: {
      search,
      limit,
      offset,
      getTotal,
      queryOption: `isReplacePriceAfterVoucher`,
      ...ctx?.query,
    },
  });
}

export async function removeWishlist(body) {
  return PUT({
    url: `${PRODUCT_API_PREFIX}/me/wishlist`,
    body,
  });
}

async function getMeWishlist({ offset = 0, limit = 10, getTotal = false } = {}) {
  return GET({
    url: `${FRONTEND_APIS_PREFIX}/me/wishlist`,
    params: {
      limit,
      offset,
      getTotal,
      queryOption: 'isReplacePriceAfterVoucher',
    },
  });
}

export const getWishlistBySkus = async ({ ctx, skus = null }) => {
  return POST({
    ctx,
    url: `${PRODUCT_API_PREFIX}/me/wishlist/list`,
    body: {
      skus,
    },
  });
};

export const getWishlist = async ({ ctx, sku = null, customerID, offset, limit }) => {
  return GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/me/wishlist`,
    params: {
      q: JSON.stringify({ sku, customerID }),
      getTotal: true,
      offset,
      limit,
    },
  });
};

const getAllWishlist = async ({ customerID }) => {
  return GET_ALL({
    url: `${PRODUCT_API_PREFIX}/me/wishlist/list`,
    params: { q: JSON.stringify({ customerID }) },
  });
};

export async function callMultiRequest({ payloads, callback, limit, limitPerRequest = 1 }) {
  const returnVariable = {
    errors: [],
    data: [],
  };
  // limit: number of payloads =)))

  for (let i = 0; i < payloads.length; i += limit * limitPerRequest) {
    const sliceDatas = payloads.slice(i, i + limit * limitPerRequest);
    const groupDatas = sliceDatas.reduce((result, current) => {
      // 2-D array, element: array of payload element
      // result[i] is array with length <= limitPerRequest
      if (result[result.length - 1]?.length < limitPerRequest) {
        result[result.length - 1] ? result[result.length - 1].push(current) : (result[result.length - 1] = [current]);
      } else {
        result[result.length] = [current];
      }

      return result;
    }, []);

    await Promise.all(
      groupDatas?.map(async (payload) => {
        try {
          await callback(payload, returnVariable);
        } catch (err) {
          console.error(err);
        }
      }),
    );
  }
  return returnVariable;
}

const updateWishlist = async (sku) =>
  POST({
    url: `${PRODUCT_API_PREFIX}/me/wishlist`,
    body: { sku },
  });

const deleteItemWishlist = async (sku) =>
  PUT({
    url: `${PRODUCT_API_PREFIX}/me/wishlist`,
    body: { sku },
  });

const getListSkuByProductId = async ({ ctx, productIds }) =>
  GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/sku/list-by-product`,
    params: { productIds },
  });

export default {
  getMeWishlist,

  getWishlistBySkus,
  updateWishlist,
  deleteItemWishlist,
  getListSkuByProductId,
  getWishlist,
  getAllWishlist,
};
