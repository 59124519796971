import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { getFirst, isValid } from 'clients/Clients';
import WebServiceClient from 'clients/WebServiceClient';
import clsx from 'clsx';
import ImageHoverEffect from 'components-v2/mocules/ImageHoverEffect';
import SearchInput from 'components-v2/mocules/SearchInput';
import LinkComp from 'components/atoms/LinkComp';
import { ARROW_DOWN, ARROW_DOWN_ACTIVE } from 'constants/Icons';
import { CARTV2_ICON } from 'constants/Images';
import { CART_URL, KHUYEN_MAI, KHUYEN_MAI_LOADING, PRODUCTS_LOADING_URL, PRODUCTS_URL, SELLERS, SELLERS_LOADING_URL } from 'constants/Paths';
import { useAuth } from 'context/Auth';
import { useSearch } from 'context/Search';
import { useRouter } from 'next/router';
import { memo, useEffect, useState } from 'react';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import { getLinkProxy } from 'utils/ImageUtils';
import NotifyUtils from 'utils/NotifyUtils';
import gtag from 'utils/gtag';
import SubMenu from './SubMenu';
import styles from './styles.module.css';
import useCartStates from 'zustand-lib/useCart';

const getUrl = (url, redirectUrl) => {
  if (redirectUrl) {
    return redirectUrl;
  }
  return url;
};

const getTrackingData = (url) => {
  const dataTracking = [
    {
      action: 'CLICK_GO_TO_PRODUCTS_PAGE',
      trackingPath: '/products',
    },
    {
      action: 'CLICK_GO_TO_INGREDIENTS_PAGE',
      trackingPath: '/ingredients',
    },
    {
      action: 'CLICK_GO_TO_QUICKORDER_PAGE',
      trackingPath: '/quick-order',
    },
    {
      action: 'CLICK_GO_TO_CAMPAIGN_PAGE',
      trackingPath: '/khuyenmai',
    },
    {
      action: 'CLICK_GO_TO_PROMO_CODES_PAGE',
      trackingPath: '/promo-codes',
    },
    {
      action: 'CLICK_GO_TO_SELLER_PAGE',
      trackingPath: '/sellers',
    },
  ];

  // eslint-disable-next-line no-restricted-syntax
  for (const data of dataTracking) {
    if (url.includes(data.trackingPath)) return data;
  }

  return {
    action: 'CLICK_GO_TO_EVENTS_PAGE',
    trackingPath: '/events',
  };
};

const KEY_MENU_BAR = 'menuBar';

const MINUTE_CACHE_MENU = 5;

function Navbar() {
  const { isAuthenticated, toggleLogin, user } = useAuth();
  const { isFocus } = useSearch();
  const router = useRouter();
  const { totalQuantity } = useCartStates();
  const [menuBar, setMenuBarData] = useState([]);

  // console.log('fix', fix NFTS CHECKOUT WINDOW);
  let menuBarRender = [];
  if (menuBar?.length > 0) {
    menuBarRender = menuBar.map((item, index) => {
      const { label, url, iconWebActiveUrl, iconWebUrl, subMenuItems = [] } = item;
      return {
        id: index,
        name: label,
        icon: getLinkProxy(iconWebUrl),
        iconActive: getLinkProxy(iconWebActiveUrl),
        url,
        isNew: false,
        width: '20px',
        height: '20px',
        eventPage: false,
        tracking: getTrackingData(url),
        subMenuItems,
      };
    });
  }

  const getActivePage = () => {
    const { pathname } = router || {};
    if (pathname === '/products' || pathname === '/categories/[slug]' || pathname === '/manufacturers/[slug]' || pathname === PRODUCTS_LOADING_URL) {
      return PRODUCTS_URL;
    }
    if (pathname === SELLERS_LOADING_URL) {
      return SELLERS;
    }
    if (pathname === KHUYEN_MAI_LOADING) {
      return KHUYEN_MAI;
    }
    return router.pathname;
  };

  const handleCheck = (e) => {
    if (!isAuthenticated) {
      e.preventDefault();
      NotifyUtils.error('Bạn cần đăng nhập để vào trang này');
      setTimeout(() => {
        toggleLogin();
      }, 300);
    }
    // setClick(false);
  };

  // useEffect(() => {
  //   gtag.displayTopSearch();
  // }, []);

  const Cart = ({ className }) => (
    <Box>
      <LinkComp href={CART_URL} className={clsx(styles.cartLink, className)}>
        <Badge badgeContent={totalQuantity} max={1000} color="secondary" overlap="rectangular" data-test="cart-num">
          <Tooltip title="Giỏ hàng" arrow>
            <Box className={styles.cart} data-test="cart-link">
              <ImageFallbackStatic src={CARTV2_ICON} width="20px" height="24px" layout="fixed" />
            </Box>
          </Tooltip>
        </Badge>
      </LinkComp>
    </Box>
  );

  const renderMenu = () =>
    menuBarRender?.map((element) => {
      const isParent = element?.subMenuItems?.length > 0;
      const isActive = element.url === getActivePage();
      const menuName = isParent ? element.name : '';
      const updatedSubMenuItems = element?.subMenuItems?.map((item) => ({
        ...item,
        menuName,
      }));

      const renderItems = (props) => (
        <div className={clsx(styles.link, isActive && styles.active)} key={element.url}>
          <LinkComp
            className={clsx(styles.flexItem)}
            name={element.name}
            href={getUrl(element.url, element.redirectUrl)}
            target={element.redirectUrl && '_blank'}
            onClick={(e) => {
              handleCheck(e);
              gtag.clickMenubar(element);
            }}
            padding="0px"
            {...props}
          >
            {element?.icon && (
              <div className={styles.menuIcons}>
                <ImageHoverEffect
                  defaultImage={element?.icon}
                  activeImage={element?.iconActive}
                  hovered={element.url === getActivePage(element.url)}
                />
              </div>
            )}
          </LinkComp>
        </div>
      );

      if (isParent) {
        return (
          <SubMenu key={element.url} items={updatedSubMenuItems}>
            {renderItems({
              iconRight: isActive ? <ARROW_DOWN_ACTIVE /> : <ARROW_DOWN />,
            })}
          </SubMenu>
        );
      }

      return renderItems();
    });

  const fetchMenuBar = async () => {
    const getListMenuLocal = JSON.parse(localStorage.getItem(KEY_MENU_BAR));
    const fetchData = async () => {
      const res = await WebServiceClient.getMenuBar({});
      if (isValid(res)) {
        const { items = [] } = getFirst(res);
        setMenuBarData(items);
        const expiry = new Date().getTime() + MINUTE_CACHE_MENU * 60 * 1000;
        localStorage.setItem(KEY_MENU_BAR, JSON.stringify({ data: items, dayExpired: expiry }));
      }
    };
    if (getListMenuLocal) {
      const currentTime = new Date().getTime();
      if (getListMenuLocal.dayExpired < currentTime) {
        fetchData();
      } else {
        setMenuBarData(getListMenuLocal?.data);
      }
    } else {
      fetchData();
    }
  };

  useEffect(() => {
    fetchMenuBar();
  }, []);

  return (
    <Box className={clsx(styles.nav)}>
      <Container style={{ maxWidth: '1180px' }} className={styles.wrap}>
        <Box className={clsx(styles.WrapmenuFocus)}>
          <Grid container className={clsx(styles.menu, styles.h40)} style={{ width: '100%', transition: 'width 1s' }}>
            {renderMenu()}
          </Grid>
          {/* off top search for prd */}
          {/* SEARCH PRODUCT */}
          {isAuthenticated && (
            <Box className={clsx(styles.wrapSticky)} style={{ width: '25%', transition: 'width 1s' }}>
              <SearchInput className={clsx(styles.SearchInput, isFocus && styles.scaleSearchInput)} classCustom={styles.hiddenWidth} />
              <Box className={styles.wrapStickRight}>
                <Cart className={styles.cartSticky} />
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
}
export default memo(Navbar);
